body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background: #f6f7fe;
}

.closeBtn {
  position: absolute !important;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 0 !important;
}

.closeBtn:hover,
.closeBtn:active {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.closeBtn .anticon {
  font-size: 30px;
  color: #fff;
}

.modalImg {
  width: 100%;
  max-width: 1200px;
  display: block;
  height: auto;
  object-fit: cover;
  margin: auto;
}

.underline {
  position: relative;
}

.underline::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 5px;
  width: 100px;
}

.underline--green::after {
  background-color: #00F0A9;
}

.underline--white::after {
  background-color: #fff;
}

.mb-7 {
  margin-bottom: 5rem;
}

@media (min-width: 576px) {
  .carousel {
    min-height: 218px !important;
  }
}

@media (max-width: 576px) {
  .carousel {
    min-height: 313px !important;
  }
}

@font-face {
  font-family: 'gothambook';
  src: url('./assets/fonts/gotham-book-webfont.eot');
  src: url('./assets/fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/gotham-book-webfont.woff2') format('woff2'),
       url('./assets/fonts/gotham-book-webfont.woff') format('woff'),
       url('./assets/fonts/gotham-book-webfont.ttf') format('truetype'),
       url('./assets/fonts/gotham-book-webfont.svg#gothambook') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham_blackregular';
  src: url('./assets/fonts/gotham-black-webfont.eot');
  src: url('./assets/fonts/gotham-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/gotham-black-webfont.woff2') format('woff2'),
       url('./assets/fonts/gotham-black-webfont.woff') format('woff'),
       url('./assets/fonts/gotham-black-webfont.ttf') format('truetype'),
       url('./assets/fonts/gotham-black-webfont.svg#gotham_blackregular') format('svg');
  font-weight: normal;
  font-style: normal;
}